import React from "react"
import { graphql } from "gatsby"
import PageHead from "../components/page-head"
import HeroSection from "../sections/hero"
// import _ from "lodash"
// import { Slug } from "../utilities/text-formatting"
import { FeatureParagraphs } from "../sections/features"
import { PriceList } from "../sections/prices"



export default class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = { site: props.data.allSiteJson.edges[0].node, prices: props.data.allPricesJson.edges[0].node, md: props.data.site.siteMetadata }
  }

  render() {
    // var md = this.state.md
    var st = this.state.site
    var pr = this.state.prices
    return (
      <>
        <PageHead
          keywords=""
          head={
            {
              title: st.title,
              canonicalUrl: st.siteUrl,
              description: st.description,
            }
          }
          schemaWebPage={
            {
              title: st.title,
              businessName: st.businessName,
              logoUrl: st.siteLogo,
              description: st.description
            }
          }
        />

        <HeroSection
          ctaText={st.cta_text}
          ctaUrl={st.cta_url}
          heroHeadline={st.headline}
          heroHeadlineSupport={st.support}
          heroLogoUrl={st.siteLogo}
        />
        <main className="flex flex-col items-center">
          <section id="features" className="lg:max-w-screen-lg w-full my-16">
            <FeatureParagraphs features={st.features} />
          </section>
          <section className="mx-auto text-center">
            <h2 className="text-base font-semibold tracking-wider text-primary uppercase">Fibreglass Vehicle Panels</h2>
            <p className="mt-2 text-3xl font-extrabold text-primaryText tracking-tight sm:text-4xl">
              Price List for 2021
            </p>
            <p className="p-4 max-w-prose mx-auto text-xl text-primaryTextLight">
              {/* {props.features.support} */}
              Here is our 2021 price list for fibreglass vehicle panels. Other panels are available on request and all prices include GST.              
            </p>
          </section>
           <section key={"price-list"} id="price-list" className="flex flex-col w-full items-start bg-backgroundGray p-5 lg:flex-row lg:p-5 lg:m-5 lg:max-w-screen-lg rounded-primary">
            <PriceList key={'price-list-a'} priceList={pr} start={0} end={3} />
            <PriceList key={'price-list-b'} priceList={pr} start={3} end={11} />
          </section>

        </main>
        {/* <hr/>
        <div>{JSON.stringify(md)}</div>
        <hr/>
        <div>{JSON.stringify(st)}</div>
        <hr/>
        <div>{JSON.stringify(pr)}</div>
        <hr/> */}

      </>
    )
  }
}
export const query = graphql`
  query {
    site {
      siteMetadata {
        project
      }
    }
    allSiteJson {
      edges {
        node {
          siteUrl
          businessName
          siteLogo          
          title
          headline
          support
          description
          cta_text
          cta_number
          features {
            small_title
            large_title
            support
            items {
              name
              paragraph
              icon
            }
          }          

        }
      }      
    }
    allPricesJson {
      edges {
        node {
          caption
          list {
            title
            items {
              name
              price
            }
          }          
        }        
      }
    }
  }
`
