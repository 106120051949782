import React from "react"
import Head from "../head/head"
import SchemaWebPage from "../head/schema-webpage"
// import SchemaWebPage from "../schemas/webpage"
// import SchemaFAQ from "../schemas/faq"
// import TwitterCard from "../schemas/twitter-card"
// import OpenGraphProtcol from "../schemas/open-graph-protocol"
// import GoogleTagManager from "../snippets/gtag-manager"
// import TopMenu from "../components/menus/top-menu"
import PropTypes from "prop-types"
// import * as style from "./page-header.module.css"
import _ from "lodash"

function PageHead(props) {
  // console.log(_.isEmpty(props.schemaWebPage))
  return (
    <>
      <Head
        title={props.head.title}
        description={props.head.description}
        canonicalUrl={props.head.canonicalUrl}
      />
      {/* <TwitterCard 
        site={props.twitterHandle}
        title={props.twitterTitle}
        description={props.twitterDescription}
        image={props.twitterLogoUrl}        
      /> */}

      {/* <OpenGraphProtcol
        appId={props.facebookAppId}
        image={props.logoUrlPng}
        imageAlt="Image Alt"
        url={props.thisPage.canonicalUrl}
        title={props.thisPage.title}
        description={props.thisPage.description} /> */}

      {!_.isEmpty(props.schemaWebPage)? <SchemaWebPage
        title={props.schemaWebPage.title}
        businessName={props.schemaWebPage.businessName}
        logoUrl={props.schemaWebPage.logoUrl}
        metaDescription={props.schemaWebPage.description}
      />: ''
      }
      {/* <SchemaFAQ name={props.thisPage.faqTitle} faq={props.thisPage.faq} /> */}

      {/* <GoogleTagManager code={props.googleTagManagerCode} /> */}
      <data className="keywords" value={props.keywords}></data>

      {/* <header className="TODO">
        <span className="TODO">{props.businessName}</span>
        <TopMenu parent="TODO" menuItems={props.topMenu.menuItems} />
      </header> */}

    </>
  )
}
PageHead.defaultProps = {

}

PageHead.propTypes = {
  keywords: PropTypes.string.isRequired,
  head: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    canonicalUrl: PropTypes.string.isRequired,
  }),
  topMenu: PropTypes.shape({
    menuItems: PropTypes.array.isRequired,
  }),
  schemaWebPage: PropTypes.shape({
    title: PropTypes.string.isRequired,
    businessName: PropTypes.string.isRequired,
    logoUrl: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  })
  // name: PropTypes.string.isRequired,
  // twitterTitle: PropTypes.string.isRequired,
  // twitterDescription: PropTypes.string.isRequired,    
  // twitterHandle: PropTypes.string.isRequired,
  // twitterLogoUrl: PropTypes.string.isRequired,
  // title: PropTypes.string.isRequired,
  // description: PropTypes.string.isRequired,
  // canonicalUrl: PropTypes.string.isRequired,
  // businessName: PropTypes.string.isRequired,
  // logoUrl: PropTypes.string.isRequired,
  // keywords: PropTypes.string.isRequired,
  // topMenu: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,  
}

export default PageHead