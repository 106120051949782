import React from "react"
import PropTypes from "prop-types"
import { Slug } from "../utilities/text-formatting"

export const SamplePriceList = {
  "caption": "Price List",
  "list": [

    {
      "title": "Special Items",
      "items": [{
        "name": "Magic wand",
        "price": 100
      },
      {
        "name": "Fairy Dust",
        "price": 300
      },
      {
        "name": "Whip",
        "price": 250
      },
      ]
    }
  ]
}


function PriceTitle(props) {
  return (
    <tr>
      <th className="text-left uppercase text-primary font-bold" scope="col" colSpan="2">{props.title}</th>
    </tr>
  )
}

function Part(props) {
  return (
    <tr className={`odd:bg-backgroundGrayDark ${props.isSingle ? 'bg-backgroundGrayDark' : ''}`}>
      <td className="pl-4 uppercase text-primaryText font-medium">{props.name}</td>
      <td className="text-right pr-4 text-primaryText font-medium">${props.price}</td>
    </tr>
  )
}
export function PriceList(props) {
  return (
    <table className="table-auto w-full lg:w-1/2 first:mr-5 lg:max-w-lg">
      <colgroup>
        <col />
        <col />
      </colgroup>
      <tbody>
        {props.priceList.list.slice(props.start, props.end).map(unit => {
          var isSingle = unit.items.length === 1
          var slug = Slug(unit.title)
          return (
            <React.Fragment key={slug + 'x'}>
              <PriceTitle key={slug} title={unit.title} />

              {unit.items.map((part, count) => {
                var iSlug = Slug(part.name) + count
                return (
                  <Part key={iSlug} isSingle={isSingle} name={part.name} price={part.price} />
                )
              })}

            </React.Fragment>
          )
        })}
      </tbody>
    </table>
  )
}

PriceList.defaultProps = {

}

PriceList.propTypes = {
  priceList: PropTypes.shape({
    caption: PropTypes.string.isRequired,
    "list": PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
      }))
    }))
  })
}

