import React from "react"
import PropTypes from "prop-types"
// import * as style from "./hero.module.css"
// import _ from "lodash"
// import HeroMenu from "../snippets/hero_menu"

function HeroSection(props) {
  return (
    <div className="bg-primary">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="font-extrabold text-white text-5xl">
          <span className="block">{props.heroHeadline}</span>
        </h2>
        <p className="mt-4 text-3xl leading-6 text-white">{props.heroHeadlineSupport}</p>
        <a
          href={props.ctaUrl}
          className="mt-8 w-max-20 inline-flex items-center justify-center px-5 py-3 border border-transparent text-2xl font-medium rounded-primary text-primary bg-white hover:bg-primaryLight hover:text-white sm:w-auto"
        >
          {props.ctaText}
        </a>
      </div>
    </div>
  )
}

HeroSection.defaultProps = {
  ctaUrl: `tel:0123456789`,
  ctaText: `Call To Action`,
  heroLogoUrl: ``,
  heroHeadline: `Headline`,
  heroHeadlineSupport: `Support`,
  heroLogoWidth: `100`,
  heroLogoHeight: `100`,
  menuItems: [],
}

HeroSection.propTypes = {
  ctaUrl: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  heroLogoUrl: PropTypes.string.isRequired,
  heroHeadline: PropTypes.string.isRequired,
  heroHeadlineSupport: PropTypes.string.isRequired,
  heroLogoWidth: PropTypes.string.isRequired,
  heroLogoHeight: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
}

export default HeroSection
