import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import _ from "lodash"
// import { StaticQuery, graphql } from "gatsby"

function onSchemaWebPage(props) {
  if (
    !_.isEmpty(props.title) &&
    !_.isEmpty(props.metaDescription) &&
    !_.isEmpty(props.businessName) &&
    !_.isEmpty(props.logoUrl)
  ) {
    // console.log("onSchemaWebPage", props)
    const j = {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: props.title,
      description: props.metaDescription,
      publisher: {
        "@type": "Organization",
        name: props.businessName,
        logo: {
          "@type": "ImageObject",
          url: props.logoUrl,
        },
      },
    }
    return <script type="application/ld+json">{JSON.stringify(j)}</script>
    // return <script type="application/ld+json" dangerouslySetInnerHTML={makeJsonLD(j)} />
  } else {
    return ``
  }
}

function SchemaWebPage(props) {
  return (
    <div className="application">
      <Helmet>{onSchemaWebPage(props)}</Helmet>
    </div>
  )
}

SchemaWebPage.defaultProps = {
  title: ``,
  metaDescription: ``,
  businessName: ``,
  logoUrl: ``,
}

SchemaWebPage.propTypes = {
  title: PropTypes.string,
  metaDescription: PropTypes.string,
  businessName: PropTypes.string,
  logoUrl: PropTypes.string,
}

export default SchemaWebPage
