import React from "react"
import PropTypes from "prop-types"
import { Slug } from "../utilities/text-formatting"
import _ from "lodash"


import {
  ShoppingBagIcon,
  ScaleIcon,
  AcademicCapIcon,
  TruckIcon,
  ArchiveIcon,
  ClipboardCheckIcon
} from '@heroicons/react/outline'

var iconsUsed = {
  "truck": TruckIcon,
  "archive": ArchiveIcon,
  "clipboard": ClipboardCheckIcon,
  "shopping-bag": ShoppingBagIcon,
  "scale": ScaleIcon,
  "academic-cap": AcademicCapIcon,
}




function GetIcon(props) {
  var ic = iconsUsed[props.name]
  // console.log(ic)
  if (!ic) {
    throw new  Error(`Cannot find icon named ${props.name}`)
  }
  return ic(props)
}

export const SampleFeaturePoints = {
  small_title: "Be Magic",
  large_title: "Everything you need for magic",
  support: "Welcome to the Magic Wands. Here is a sample of what is available.",
  items: [
    {
      "name": "Wands",
      "points": ["Small", "Medium", "Large"],
      "icon": 'shopping-bag',
    },
    {
      "name": "Fairy Dust",
      "points": ["1kg Bag", "5kg Bag", "10kg Bag"],
      "icon": 'scale',
    },
    {
      "name": "General",
      "points": ["Spells", "Curses", "Healing"],
      "icon": 'academic-cap',
    }
  ]
}

export const SampleFeatureParagraphs = {
  small_title: "Be Magic",
  large_title: "Everything you need for magic",
  support: "Welcome to the Magic Wands. Here is a sample of what is available.",
  items: [
    {
      "name": "Wands",
      "paragraph": "All our wands come with a 10000 year warranty",
      "icon": 'shopping-bag',
    },
    {
      "name": "Fairy Dust",
      "paragraph": "Dust that won't make you sneeze",
      "icon": 'scale',
    },
    {
      "name": "General",
      "paragraph": "If its magic, we have it",
      "icon": 'academic-cap',
    }
  ]
}

export function FeaturePoints(props) {
  return (
    <div className="lg:max-w-screen-lg w-full bg-white mb-8">
      <div className="mx-auto text-center">
        <h2 className="text-base font-semibold tracking-wider text-primary uppercase">{props.features.small_title}</h2>
        <p className="mt-2 text-3xl font-extrabold text-primaryText tracking-tight sm:text-4xl">
          {props.features.large_title}
        </p>
        <p className="p-4 max-w-prose mx-auto text-xl text-primaryTextLight">
          {props.features.support}
        </p>
        <div className="mt-12">
          <div className="grid max-w-xs lg:max-w-full grid-cols-1 gap-8 sm:grid-cols-1 lg:grid-cols-3 justify-center m-auto">
            {props.features.items.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root bg-backgroundGray rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    {
                      !_.isEmpty(feature.icon) ?
                        <div>
                          <span className="inline-flex items-center justify-center p-3 bg-primary rounded-primary shadow-lg">
                            <GetIcon name={feature.icon} className="h-6 w-6 text-white" aria-hidden="true" />
                          </span>
                        </div> : ''
                    }
                    <h3 className="mt-8 mb-4 text-xl font-medium text-primaryText tracking-tight">{feature.name}</h3>
                    {/* <p className="mt-5 text-base text-primaryTextLight">
                      Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.
                    </p> */}
                    <ul className="text-center">
                      {feature.points.map(point => {
                        return (
                          <li key={Slug(point + feature.name)} className="text-lg text-primaryText mb-1">{point}</li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

FeaturePoints.defaultProps = {

}

FeaturePoints.propTypes = {
  features: PropTypes.shape({
    small_title: PropTypes.string.isRequired,
    large_title: PropTypes.string.isRequired,
    support: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      points: PropTypes.arrayOf(PropTypes.string).isRequired,
      icon: PropTypes.string.isRequired,
    }))
  })
}

export function FeatureParagraphs(props) {
  return (
    <div className="lg:max-w-screen-lg w-full bg-white mb-8">
      <div className="mx-auto text-center">
        <h2 className="text-base font-semibold tracking-wider text-primary uppercase">{props.features.small_title}</h2>
        <p className="mt-2 text-3xl font-extrabold text-primaryText tracking-tight sm:text-4xl">
          {props.features.large_title}
        </p>
        <p className="p-4 max-w-prose mx-auto text-xl text-primaryTextLight">
          {props.features.support}
        </p>
        <div className="mt-12">
          <div className="grid max-w-xs lg:max-w-full grid-cols-1 gap-8 sm:grid-cols-1 lg:grid-cols-3 justify-center m-auto">
            {props.features.items.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root bg-backgroundGray rounded-lg px-6 pb-8 h-full">
                  <div className="-mt-6">
                    {
                      !_.isEmpty(feature.icon) ?
                        <div>
                          <span className="inline-flex items-center justify-center p-3 bg-primary rounded-primary shadow-lg">
                            <GetIcon name={feature.icon} className="h-6 w-6 text-white" aria-hidden="true" />
                          </span>
                        </div> : ''
                    }
                    <h3 className="mt-8 mb-4 text-xl font-medium text-primaryText tracking-tight">{feature.name}</h3>
                    <p className="mt-5 text-base text-primaryTextLight">
                    {feature.paragraph}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}


FeatureParagraphs.defaultProps = {

}

FeatureParagraphs.propTypes = {
  features: PropTypes.shape({
    small_title: PropTypes.string.isRequired,
    large_title: PropTypes.string.isRequired,
    support: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      paragraph: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    }))
  })
}