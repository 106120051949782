import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"

function Head(props) {
  return (   
    <div className="application">
      <Helmet>
        <html lang={props.lang} />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={props.description} />
        <link rel="canonical" href={props.canonicalUrl} />        
        <title>{props.title}</title>
       </Helmet>
    </div> )  
}

Head.defaultProps = {
  lang: `en-AU`,
  description: ``,
  canonicalUrl: ``,
  title: ``,  

}

Head.propTypes = {
  lang: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  canonicalUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default Head