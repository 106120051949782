// import _ from "lodash"
const _ = require('lodash')

const invalidPhoneNumberLength = (isLength, shouldLength) => `Phone number length ${isLength} is invalid. Should be ${shouldLength}.`  
const rxDigits = new RegExp(/[0-9]+/, 'g');

function WordsToList(text) {
  return text.split(",").map(x => _.startCase(_.camelCase(x.trim()))).filter(x => !_.isEmpty(x));
}

function WordsToSet(setRef, text) {
  WordsToList(text).forEach(x => setRef.add(x));  
}

function ToSet(setRef, array) {
  array.forEach(x => setRef.add(x))
}

function SortSet(setRef) {
  var l = Array.from(setRef);
  l.sort();
  return l;
}

function Slug(text) {
  return _.snakeCase(text.toLowerCase());  
}

function JustDigits(ph) {
  var m = ph.match(rxDigits)
  if (!_.isEmpty(m)) {
    return m.join('')
  }
  return ''
}


function FormatShortLandline(phone) {
  if (phone.length === 8) {
    return `${phone.substring(0, 4)} ${phone.substring(4, 8)}`;
  } else {
    throw Error(invalidPhoneNumberLength(phone.length, 8));
  }
}

function FormatLongLandline(phone) {
  if (phone.length === 10) {
    return `${phone.substring(0, 2)} ${phone.substring(2, 6)} ${phone.substring(6, 10)}`;
  } else {
    throw Error(invalidPhoneNumberLength(phone.length, 10));
  }
}

function FormatMobile(phone) {
  if (phone.length === 10) {
    return `${phone.substring(0, 4)} ${phone.substring(4, 7)} ${phone.substring(7, 10)}`;
  } else {
    throw Error(invalidPhoneNumberLength(phone.length, 10));
  }
}

function FormatPhoneNumber(ph) {
  if (ph.length === 8) {
    return FormatShortLandline(ph);
  }

  if (ph.length === 10) {
    if (ph.startsWith('08')) {
      return FormatLongLandline(ph);
    }

    if (ph.startsWith('04')) {
      return FormatMobile(ph);
    }

    if (ph.startsWith('1300')) {
      return FormatMobile(ph);
    }
  }
}

exports.WordsToList = WordsToList
exports.WordsToSet = WordsToSet
exports.SortSet = SortSet
exports.ToSet = ToSet
exports.Slug = Slug
exports.FormatShortLandline = FormatShortLandline
exports.FormatLongLandline = FormatLongLandline
exports.FormatMobile = FormatMobile
exports.FormatPhoneNumber = FormatPhoneNumber
exports.JustDigits = JustDigits